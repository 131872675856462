<template>
  <div v-if="isAuthenticated && organization">
    <div v-if="organization.baseModuleActive">
      <div class="grid-x grid-padding-x">
        <div class="cell callout success">
          <h3>
            <i class="fi-torsos-female-male" style="color: black"></i>&nbsp;&nbsp;&nbsp;Eltern-Übersicht
          </h3>
        </div>
      </div>
      <div v-if="facilitiesNotExists" class="grid-x grid-padding-x grid-padding-y">
        <NoFacilityCard functionTitle="Eltern"></NoFacilityCard>
      </div>
      <div v-else class="grid-x grid-padding-x grid-padding-y">
        <div class="cell medium-6">
          <md-table id="parents-table-id" v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header
                    @md-selected="onSelect" style="height: 1048px;">
            <md-table-toolbar>
              <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
                <div class="cell large-8 medium-8 small-8">
                  <br>
                  <p class="md-title" style="margin-left: 0;">{{ searched.length }} Bezugspersonen <span
                      class="hide-for-medium-only hide-for-small-only">verfügbar</span></p>
                  <br>
                </div>
                <div class="cell large-2 medium-2 small-2">
                  <md-button class="md-icon-button md-raised md-primary"
                             style="background: cornflowerblue" @click="onShowAllRelatedPersons">
                    <md-icon>select_all</md-icon>
                    <md-tooltip md-direction="bottom" style="font-size: medium;">
                      <span>Alle <b>{{ relatedPersons.length }}</b> Bezugspersonen der Einrichtung <b>{{ selectedFacilityName }}</b> anzeigen</span>
                    </md-tooltip>
                  </md-button>
                </div>
                <div class="cell large-2 medium-2 small-2">
                  <div v-if="sending">
                    <vue-simple-spinner></vue-simple-spinner>
                  </div>
                </div>

                <div v-if="!isSage && availableFacilities && availableFacilities.length > 0"
                     class="cell large-6 medium-12 small-10">
                  <md-field>
                    <label>Einrichtung</label>
                    <md-select v-model="selectedFacilityReferenceNumber"
                               :disabled="sending || availableFacilities.length <= 1"
                               @md-selected="onSelectFacility">
                      <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                                 :key="facility.referenceNumber">
                        {{ facility.longName }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="cell large-6 medium-12 small-12">
                  <span class="md-caption">Elterportal</span><br>
                  <md-radio class="md-primary" v-model="filterPortalLogin" :value="'all'" @change="searchOnTable">
                    <md-icon style="margin-left: 8px; margin-right: -16px;">groups</md-icon>
                    <md-tooltip>Alle anzeigen</md-tooltip>
                  </md-radio>
                  <md-radio class="md-primary" v-model="filterPortalLogin" :value="'access'" @change="searchOnTable">
                    <md-icon style="color: green">desktop_windows</md-icon>
                    <md-tooltip>Nur Eltern <b>mit Zugang</b> zum Elternportal</md-tooltip>
                  </md-radio>
                  <md-radio class="md-primary" v-model="filterPortalLogin" :value="'noAccess'" @change="searchOnTable">
                    <md-icon style="color: lightgrey">desktop_access_disabled</md-icon>
                    <md-tooltip>Nur Eltern <b>ohne Zugang</b> zum Elternportal</md-tooltip>
                  </md-radio>
                  <md-radio class="md-primary" v-model="filterPortalLogin" :value="'notPossible'"
                            @change="searchOnTable">
                    <md-icon style="color: palevioletred">not_interested</md-icon>
                    <md-tooltip>Nur Eltern <b>ohne Zugangvoraussetzung</b> für das Elternportal</md-tooltip>
                  </md-radio>
                </div>
                <div class="cell large-6 medium-12 small-12">
                  <md-field md-clearable class="md-toolbar-section-end">
                    <md-input placeholder="Suche nach Nachname ..." v-model="search" @input="searchOnTable"/>
                  </md-field>
                </div>
                <div class="cell large-6 medium-12 small-12">
                  <md-field md-clearable class="md-toolbar-section-end">
                    <md-input placeholder="Suche nach Email ..." v-model="searchEmail" @input="searchOnTable"/>
                  </md-field>
                </div>

                <div class="cell" style="padding-bottom: 15px;">
                  <div v-if="!isSage" class="grid-x grid-padding-x">
                    <div class="cell large-7 hide-for-medium-only hide-for-small-only">
                    </div>
                    <div class="cell large-5 medium-12 ">
                    </div>
                  </div>
                </div>
              </div>
            </md-table-toolbar>

            <md-table-empty-state v-if="search || searchEmail"
                                  md-label="Keine Bezugsperson gefunden"
                                  :md-description="`Keine Bezugsperson mit dem Suchbegriff '${search}' oder der Email '${searchEmail}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
            </md-table-empty-state>

            <md-table-row :id="item.person.id" slot="md-table-row" slot-scope="{ item }"
                          :class="getClass(item.person.id)" md-selectable="single">
              <md-table-cell md-label="Nachname" md-sort-by="person.lastname">{{ item.person.lastname }}
              </md-table-cell>
              <md-table-cell md-label="Vorname" md-sort-by="person.firstname">{{ item.person.firstname }}
              </md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="person.contact.email">{{ item.person.contact.email }}
              </md-table-cell>
              <md-table-cell md-label="Beziehung" md-sort-by="person.childRelation">
                {{ labels.childRelation[item.person.childRelation] }}
              </md-table-cell>
              <md-table-cell md-label="Elternportal" md-sort-by="person.portalLoginAllowed">
                <md-icon v-if="!item.person.portalLoginPossible" style="color: palevioletred">not_interested</md-icon>
                <md-icon v-else-if="item.person.portalLoginAllowed" style="color: green">desktop_windows</md-icon>
                <md-icon v-else style="color: lightgrey">desktop_access_disabled</md-icon>
              </md-table-cell>
              <md-table-cell md-label="Kinder" md-sort-by="relatedChilds.length">{{ item.relatedChilds.length }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>

        <div class="cell medium-6">
          <md-card v-if="!isSage" style="min-height: 1048px;">

            <md-card-header>
              <p v-if="selectedRelatedPerson" class="md-title">
                <span
                    class="hide-for-medium-only hide-for-small-only">{{ labels.childRelation[selectedRelatedPerson.person.childRelation] }}: </span>
                <span class="related-person-title-thin">{{ selectedRelatedPerson.person.fullName }}</span>
              </p>
              <h5 v-else><i>Bitte wählen Sie eine Bezugsperson aus</i></h5>
            </md-card-header>

            <md-card-content>
              <br>
              <div v-if="selectedRelatedPerson" class="grid-x grid-padding-x grid-padding-y">
                <div class="cell large-6 medium-6">
                  <p class="md-subhead" style="font-size: x-large; padding-bottom: 10px;"><span
                      style="color: lightseagreen;">Adresse</span></p>
                  <hr>
                  <PersonView :facilityRef="selectedFacilityReferenceNumber" :person="selectedRelatedPerson.person"
                              :birthDateDisabled="true" :showMore="false" :gdprDisabled="true"/>
                </div>
                <div class="cell large-6 medium-6">
                  <p class="md-subhead" style="font-size: x-large; padding-bottom: 10px;"><span
                      style="color: lightseagreen;">Kontakt</span></p>
                  <hr>
                  <ContactView :contact="selectedRelatedPerson.person.contact"/>
                </div>
                <div class="cell large-6 medium-6">
                  <p class="md-subhead" style="font-size: x-large; padding-bottom: 10px;"><span
                      style="color: lightseagreen;">Elternportal</span></p>
                  <hr>
                  <div class="grid-x">
                    <div class="small-6">
                      <label class="small-label">Zugang Elternportal
                        <md-tooltip>
                          Das Elternportal bietet einen Online-Zugang für Eltern für eine Vielzahl von Informationen,
                          die von Einrichtungen zur Verfügung gestellt werden können.
                        </md-tooltip></label>
                      <div v-if="isPortalLoginPossible(selectedRelatedPerson.person)">
                        <md-switch class="md-primary"
                                   v-model="selectedRelatedPerson.person.portalLoginAllowed"
                                   @change="onUpdateParentalLogin(selectedRelatedPerson.person, 1)">
                          <p v-if="selectedRelatedPerson.person.portalLoginAllowed">ja</p>
                          <p v-else>nein</p>
                        </md-switch>
                      </div>
                    </div>
                    <div class="small-6" v-if="isPortalLoginPossible(selectedRelatedPerson.person) && selectedRelatedPerson.person.portalLoginAllowed">
                      <label class="small-label">Self-Service
                        <md-tooltip>
                          Mit der Self-Service Funktion können Eltern ihre eigenen Stamm- und Vertagsdaten im Elternportal bearbeiten.
                        </md-tooltip>
                      </label>
                      <md-switch class="md-primary"
                                 v-model="selectedRelatedPerson.person.selfServiceAllowed"
                                 @change="onUpdateParentalLogin(selectedRelatedPerson.person, 2)">
                        <p v-if="selectedRelatedPerson.person.selfServiceAllowed">ja</p>
                        <p v-else>nein</p>
                      </md-switch>
                    </div>
                    <div v-if="!isPortalLoginPossible(selectedRelatedPerson.person)" class="Subheading" style="padding-top: 16px; color: red">Voraussetzung für Zugang
                      nicht gegeben.<span hidden>{{ selectedRelatedPerson.person.portalLoginAllowed = false }}</span>
                    </div>
                    <md-tooltip>
                      <div v-if="!isPortalLoginPossible(selectedRelatedPerson.person)">
                        Im Elternportal können vertrauliche Informationen des<br>
                        Kindes an diese Bezugsperson weitergegeben werden.<br>
                        Aus diesem Grund muss für den Zugang zum Elternportal<br>
                        ein <b>Sorgerecht</b> vorliegen, eine gültige <b>Email-Adresse</b><br>
                        und das <b> Geburtsdatum</b> der Bezugsperson angegeben sein.
                      </div>
                    </md-tooltip>
                  </div>
                  <div>
                    <md-card style="box-shadow: none" md-with-hover>
                      <router-link v-if="selectedRelatedPerson.person.portalLoginAllowed && selectedRelatedPerson.userdata"
                                   :to="'/Verwaltung/Benutzer/'+ selectedRelatedPerson.userdata.id">
                        <UserView :user="selectedRelatedPerson.userdata"></UserView>
                        <md-tooltip md-delay="500" style="font-size: medium;">Benutzer
                          <b>{{ selectedRelatedPerson.userdata.firstName }}
                            {{ selectedRelatedPerson.userdata.lastName }}</b> bearbeiten
                        </md-tooltip>
                      </router-link>
                    </md-card>
                  </div>
                </div>
                <div class="cell large-6 medium-6">
                  <p class="md-subhead" style="font-size: x-large; padding-bottom: 10px;"><span
                      style="color: lightseagreen;">Details</span></p>
                  <hr>
                  <PersonView :facilityRef="selectedFacilityReferenceNumber" :person="selectedRelatedPerson.person"
                              :fullNameDisabled="true" :noAddress="true" :showMore="true"/>
                </div>
                <div class="cell">
                  <p class="md-subhead" style="font-size: x-large; padding-bottom: 10px;"><span
                      style="color: lightseagreen;">Kinder</span></p>
                  <hr>
                  <div class="grid-x">
                    <ChildCard class="cell large-6" v-for="child of selectedRelatedPerson.relatedChilds" :child="child"
                               :key="child.id" :groups="groups"/>
                  </div>
                </div>
              </div>
            </md-card-content>

          </md-card>
        </div>
      </div>
    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="BASE" moduleFunction="Eltern"></NoModuleCard>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
import Spinner from 'vue-simple-spinner';
import {Md5} from 'ts-md5/dist/md5';
import HttpErrorHandler from '../services/HttpErrorHandler';
import ParentsService from "../services/ParentsService";
import NoFacilityCard from "../components/cards/NoFacilityCard";
import NoModuleCard from '../components/cards/NoModuleCard';
import PersonView from "../components/views/PersonView";
import UserView from "../components/views/UserView";
import ContactView from "../components/views/ContactView";
import ChildCard from "../components/cards/ChildCard";
import GroupService from "../services/GroupService";

const toLower = text => {
  if (text) {
    return text.toString().toLowerCase();
  } else {
    return '';
  }
};

const searchByLastName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.person.lastname).includes(toLower(term)));
  }
  return items;
};

const searchByEmail = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.person.contact.email).includes(toLower(term)));
  }
  return items;
};

const checkIt = (item, term) => {
  if (term === 'access') {
    return (item.person.portalLoginAllowed && item.person.portalLoginPossible);
  }
  if (term === 'noAccess') {
    return (!item.person.portalLoginAllowed && item.person.portalLoginPossible);
  }
  if (term === 'notPossible') {
    return !item.person.portalLoginPossible;
  }
  return term === 'all';

};

const searchByPortalLoginAllowed = (items, term) => {
  if (term) {
    return items.filter(item => checkIt(item, term));
  }
  return items;
};

export default {
  name: 'Parents',

  components: {
    ChildCard,
    ContactView,
    NoFacilityCard,
    NoModuleCard,
    PersonView,
    UserView,
    'vue-simple-spinner': Spinner,
  },

  mounted() {
    this.restoreUserSettings();
    this.reloadParents();

    HttpErrorHandler.maintainDarkMode(this);
  },

  beforeDestroy() {
    this.selectedClosingTime = null;
    this.selected = null;
  },

  data() {
    return {
      sending: false,
      relatedPersons: [],
      searched: [],
      search: '',
      searchEmail: '',
      selected: null,

      filterPortalLogin: 'all',

      selectedRelatedPerson: null,
      lastselectedRelatedPerson: null,
      selectedRelatedPersonMd5Hash: null,

      dialogMode: 'update',

      groups: [],
      availableFacilities: [],
      allowedFacilities: [],
      selectedFacilityReferenceNumber: null,
      selectedFacilityName: '',

      selectedRowId: 0,

      showExportTaxCertificatesDialog: false,
      confirmSaveChangedDataDialog: false,

      showDeleteClosingTimeDialog: false,
      showAddClosingTimeDialog: false,
    }
  },

  methods: {

    booleanText(value) {
      return value ? 'ja' : 'nein';
    },

    restoreUserSettings() {
      if (this.user.md5) {
        if (this.$route.params['id']) {
          localStorage.setItem(this.user.md5 + '@selectedParentRowId', this.$route.params['id']);
          localStorage.setItem(this.user.md5 + '@openParentFromUrl', this.$route.params['id']);
        } else {
          localStorage.removeItem(this.user.md5 + '@openParentFromUrl');
        }
      }

      this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
      if (this.selectedFacilityReferenceNumber === '*') {
        this.selectedFacilityReferenceNumber = null;
      }
    },

    restoreDialogSelections() {
      if (localStorage.getItem(this.user.md5 + '@selectedParentRowId')) {
        this.selectedRowId = localStorage.getItem(this.user.md5 + '@selectedParentRowId');
      }

      if (this.selectedRowId !== null && this.selectedRowId !== 0) {
        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const retryIntervalId = setInterval(() => {

          if (this.selectedRowId === 0) {
            maxTries = 0;
            clearInterval(retryIntervalId);
          }

          let row = jQuery('#' + this.selectedRowId);
          if (row && row.length > 0) {
            clearInterval(retryIntervalId);

            if (this.selectedRowId === localStorage.getItem(this.user.md5 + '@openParentFromUrl')) {
              this.selectParentId(this.selectedRowId);
            }
          } else {
            maxTries--;
            if (maxTries <= 0) {
              clearInterval(retryIntervalId);
            }
          }
        }, 250);
      }
    },

    initData() {
    },

    onShowAllRelatedPersons() {
      this.search = '';
      this.searchEmail = '';
      this.filterPortalLogin = 'all';
      this.searchOnTable();
    },

    selectParentId(id) {
      setTimeout(() => {
        jQuery('#' + id).trigger('click');
        let list = jQuery('#parents-table-id').children().first().next().next();
        list.animate({scrollTop: document.getElementById(id).offsetTop}, 'slow');
      }, 500);
    },

    buildAvailableFacilities() {
      this.availableFacilities = this.getAvailableFacilities;
      for (let i = 0; i < this.availableFacilities.length; i++) {
        if (this.availableFacilities[i].referenceNumber === '*') {
          this.availableFacilities = this.availableFacilities.slice(0, i).concat(this.availableFacilities.slice(i + 1, this.availableFacilities.length));
        }
      }
      if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
        this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
        this.selectedFacilityName = this.availableFacilities[0].name;
      }
      let i = 0;
      for (let facility of this.availableFacilities) {
        if (i > 0) {
          this.allowedFacilities.push(facility);
        }
        i++;
      }
      this.onSelectFacility();
      this.initData();
    },

    reloadParents() {
      let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
      const reloadIntervalId = setInterval(() => {
        if (this.dataAvailable) {
          if (this.isAdmin || this.isTreasurer) {
            if (!this.selectedFacilityReferenceNumber) {
              this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
            }
            this.getAllOrganizationGroups();
            this.buildAvailableFacilities();
            this.getParents();
          } else {
            if (!this.selectedFacilityReferenceNumber) {
              this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
            }
            this.buildAvailableFacilities();
          }

          clearInterval(reloadIntervalId);
          jQuery('.md-content.md-table-content.md-scrollbar').attr('style', 'height: 605px');

          this.restoreDialogSelections();

        } else {
          this.sending = true;
          maxTries--;
          if (maxTries <= 0) {
            this.sending = false;
            clearInterval(reloadIntervalId);
            HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
          }
        }
      }, 250);
    },

    getParents() {
      if (this.selectedFacilityReferenceNumber) {
        this.getFacilityParents(this.selectedFacilityReferenceNumber);
      }
    },

    getFacilityParents(referenceNumber) {
      if (!this.facilitiesNotExists) {
        this.sending = true;
        ParentsService.getRelatesPersons(referenceNumber)
            .then(response => {
              this.relatedPersons = response.data;
              this.searchOnTable();
              this.sending = false;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Bezugspersonen für Einrichtung mit Nummer <b>' + referenceNumber + '</b>');
              this.sending = false;
            })
      }
    },

    getAllOrganizationGroups() {
      this.sending = true;
      GroupService.getAllOrganizationGroups()
          .then(response => {
            this.groups = response.data;
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Gruppen für Träger ' + this.organization.name);
            this.sending = false;
          })
    },

    searchOnTable() {
      this.searched = this.relatedPersons;
      this.searched = searchByLastName(this.searched, this.search);
      this.searched = searchByEmail(this.searched, this.searchEmail);
      this.searched = searchByPortalLoginAllowed(this.searched, this.filterPortalLogin);
    },

    getClass: ({id}) => ({
      'md-primary': id === id
    }),

    onSelect(item) {
      if (item) {
        if (this.selectedRelatedPersonMd5Hash && !this.pristine()) {
          this.onSaveChanges();
        }
        this.selectedRowId = item.person.id;
        this.selected = JSON.parse(JSON.stringify(item));
        this.selectedRelatedPerson = JSON.parse(JSON.stringify(this.selected));
        this.selectedRelatedPersonMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.selectedRelatedPerson));
      } else {
        this.selected = null;
        this.selectedRelatedPerson = null;
        this.selectedRelatedPersonMd5Hash = null;
      }
    },

    onSaveChanges() {
      this.lastselectedRelatedPerson = this.selectedRelatedPerson;
      this.confirmSaveChangedDataDialog = true;
    },

    onSelectFacility() {
      if (this.selectedFacilityReferenceNumber && this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

        localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);

        for (let fac of this.availableFacilities) {
          if (fac.referenceNumber === this.selectedFacilityReferenceNumber) {
            this.selectedFacilityName = fac.name;
          }
        }

        this.selected = null;
        this.selectedRelatedPerson = null;
        this.selectedRelatedPersonMd5Hash = null;

        if (this.selectedFacilityReferenceNumber === '*') {
          this.getParents();
        } else {
          this.getFacilityParents(this.selectedFacilityReferenceNumber);
        }
      }
    },

    isPortalLoginPossible(person) {
      return (person.portalLoginPossible /*&& (person.custody || person.soleCustody) && person.contact.email*/);
    },

    onUpdateParentalLogin(relatedPerson, mode) {
      if (this.selectedRelatedPerson) {
        setTimeout(() => {
        ParentsService.updateLoginForPerson(relatedPerson)
            .then((response) => {
              this.updateSelectedPerson(response.data);
              let msg = '';
              if (mode === 1) {
                msg = 'Der Zugang zum Elternportal für Bezugsperson&nbsp;<b>' + relatedPerson.fullName + '</b>&nbsp;wurde erfolgreich ';
                if (this.selectedRelatedPerson.person.portalLoginAllowed) {
                  msg += '<b>aktiviert</b>.';
                } else {
                  msg += '<b>deaktiviert</b>.';
                }
              } else
              if (mode === 2) {
                msg = 'Die Self Service Funktion für Bezugsperson&nbsp;<b>' + relatedPerson.fullName + '</b>&nbsp;wurde erfolgreich ';
                if (this.selectedRelatedPerson.person.selfServiceAllowed) {
                  msg += '<b>aktiviert</b>.';
                } else {
                  msg += '<b>deaktiviert</b>.';
                }
              }

              this.$store.commit('successMsg', msg);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Ändern der Elternportal-Einstellungen für Bezugsperson <b>' + relatedPerson.fullName + '</b>');
            })
        }, 150);
      }
    },

    updateSelectedPerson(person) {
      this.selectedRelatedPerson.person = person;
      for (let rp of this.relatedPersons) {
        if (rp.person.id === this.selectedRelatedPerson.person.id) {
          rp.person = person;
        }
      }
    },

    pristine() {
      return (this.selectedRelatedPersonMd5Hash === Md5.hashAsciiStr(JSON.stringify(this.selectedRelatedPerson)));
    },
  },

  computed: {

    facilitiesNotExists() {
      return this.organization && this.organization.facilities && this.organization.facilities.length <= 0;
    },

    dataAvailable() {
      return this.organization && this.organization.facilities && this.user && this.user.allowedFacilities &&
          this.user.allowedFacilities.length > 0 &&
          this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
    },

    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    isTreasurer() {
      return this.$store.getters.treasurer;
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    isSage() {
      return this.$store.getters.sage;
    },

    user() {
      return this.$store.getters.user;
    },

    organization() {
      return this.$store.getters.organization;
    },

    getAvailableFacilities() {
      return this.$store.getters.availableFacilities;
    },

    labels() {
      return this.$store.getters.labels
    },

    isDevEnv() {
      return process.env.NODE_ENV === 'development';
    },
  }
}
</script>

<style lang="scss" scoped>
h5 {
  font-weight: 400;
  color: lightseagreen;
}

hr {
  margin-top: 8px;
  margin-bottom: 8px;
}

.closing-time-title {
  color: chocolate;
  font-weight: bold;
  font-size: larger;
}

.md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
  background: darkseagreen;
}

.md-open-days {
  color: cornflowerblue;
  font-weight: 300;
  font-size: 1.2rem;
  text-underline: cornflowerblue;
}

.md-tooltip {
  font-size: medium;
  height: auto;
  white-space: normal;
}

.related-person-title-thin {
  color: cornflowerblue;
  font-weight: lighter;
  font-size: larger;
}

.md-radio {
  padding: 0 0 0 3px;
  margin: 16px 16px 16px 0 !important;
}

a:hover {
  text-decoration: none;
}
</style>

<style lang="scss">

.md-radio-label {
  padding-left: 10px !important;
  margin-top: -2px !important;
}

.md-radio-container {
  margin-left: 0 !important;
}

.md-table-cell-container {
  padding: 6px 6px 6px 6px !important;
}
</style>