<template>
  <div v-if="user && labels && labels.rolesprofile">
    <div v-if="raw">
      <div>{{user.firstName}} {{user.lastName}}</div>
      <div>Email : {{user.email}}</div>
      <div>Benutzer-Profil : {{labels.rolesprofile[user.rolesProfile]}}</div>
      <div>Einwilligung Datenverarbeitung : {{booleanText(user.dsgvoConfirmationObtained)}}</div>
    </div>
    <div v-else>
      <table class="hover">
        <tbody>
          <tr>
            <td class="td-width">Name</td>
            <td><p><span>{{user.firstName}} {{user.lastName}}</span></p></td>
          </tr>
          <tr>
            <td class="td-width">Email (User)</td>
            <td><p><span>{{user.email}}</span></p></td>
          </tr>
          <tr>
            <td class="td-width">Benutzer-Profil</td>
            <td><p><span>{{labels.rolesprofile[user.rolesProfile]}}</span></p></td>
          </tr>
          <tr>
            <td class="td-width">Einrichtungen</td>
            <td><p><span v-html="user.allowedFacilitiesString"></span></p></td>
          </tr>
          <tr>
            <td class="td-width">Einwilligung Datenverarbeitung</td>
            <td><p><span :class="booleanText(user.dsgvoConfirmationObtained)">{{booleanText(user.dsgvoConfirmationObtained)}}</span></p></td>
            <md-tooltip>
              <div v-if="!user.dsgvoConfirmationObtained">
                Die Einwilligung gemäß Datenschutz-Grundverordnung (Art. 6 DSGVO)<br>
                zur Verarbeitung dieser personenbezogenen Daten muss<br>
                seitens <b>{{user.firstName}} {{user.lastName}}</b> schriftlich gegeben sein.
              </div>
            </md-tooltip>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UserView',

    props: {
      user: {
        required: true
      },
      raw: {
        required: false
      },
    },

    methods: {
      booleanText(value) {
        return value ? 'ja' : 'nein';
      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },
    },
  }
</script>

<style lang="scss" scoped>
  p {
    margin: 0;
  }

  span {
    color: cornflowerblue;
  }

  table {
    border-collapse: unset;
  }

  tbody th, tbody td {
    padding: 0;
  }

  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: transparent;
  }

  .td-width {
    width: 210px;
  }

  .ja {
    color: forestgreen;
    font-weight: bold;
    font-size: large;
  }

  .nein {
    color: red;
    font-weight: bold;
    font-size: large;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }
</style>