import HttpInterceptor from "./HttpInterceptor";

export default {

  name: 'PdfService',

  getTemplateData(config) {
    return HttpInterceptor.httpClient().put('pdf/template/data', config);
  },

  createPdf(template) {
    return HttpInterceptor.httpClient().put('pdf/create', template, {responseType: 'blob', 'Content-Type': 'application/json'});
  },
}
