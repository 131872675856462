<template>
  <div class="cell text-center">
    <md-card md-with-hover class="text-left">
      <md-card-header>
        <div class="md-layout">
          <div class="md-layout-item md-size-15">
            <img width="55px" src="../../assets/icons/bulp.png"/>
          </div>
          <div v-if="isDeleted" class="md-layout-item">
            <div class="md-title">Der Vertrag ist beendet</div>
            <div class="md-subhead"><span>SoSE</span>&nbsp;kann&nbsp;ohne gültigen Vertrag nicht verwendet&nbsp;werden.</div>
          </div>
          <div v-else class="md-layout-item">
            <div class="md-title">Keine Einrichtungen angelegt</div>
            <div v-if="alternativeText" class="md-subhead" v-html="alternativeText"></div>
            <div v-else class="md-subhead"><span>{{functionTitle}}</span>&nbsp;können&nbsp;nur&nbsp;mit&nbsp;Einrichtungen&nbsp;verwaltet&nbsp;werden.</div>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-15">
          </div>
          <div v-if="isDeleted" class="md-layout-item">
            Ihre alten Vertragsdaten und Rechnungen können noch eingesehen werden.<br>
            Bitte nutzen Sie hierzu den Bereich<br><br>
            <router-link to="/Verwaltung/Traeger"><h4>Verwaltung / Träger</h4></router-link>
          </div>
          <div v-else class="md-layout-item">
            Es sind noch keine Einrichtungen angelegt.<br>
            Bitte nutzen Sie hierzu den Bereich<br><br>
            <router-link to="/Verwaltung/Einrichtungen"><h4>Verwaltung / Einrichtungen</h4></router-link>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
  export default {
    name: 'NoFacilityCard',
    props: {
      functionTitle: {
        required: false
      },
      alternativeText: {
        required: false
      },
    },

    computed: {
      isDeleted() {
        return this.$store.getters.deleted;
      },
    },
  }
</script>

<style lang="scss" scoped>
  span {
    color: cornflowerblue;
    font-weight: bold;
  }

  .md-card {
    max-width: 600px;
    min-width: 200px;
    margin: 4px;
    display: inline-block;
    vertical-align: top;
  }
</style>
