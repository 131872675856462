<template>
  <div v-if="contact">
    <div v-if="raw">
      <div>Email : {{contact.email}}</div>
      <div>Tel (Mobil) : {{contact.mobilePhone}}</div>
      <div>Tel (Zuhause) : {{contact.phone}}</div>
      <div>Tel (Arbeit) : {{contact.businessPhone}}</div>
      <div>Fax : {{contact.fax}}</div>
    </div>
    <div v-else>
      <table v-if="insurance" class="hover">
        <tbody>
        <tr>
          <td class="td-width"><p>Email (Versicherung)</p></td>
          <td><span>{{contact.email}}</span></td>
        </tr>
        <tr>
          <td class="td-width"><p>Tel (Versicherung)</p></td>
          <td><span>{{contact.businessPhone}}</span></td>
        </tr>
        </tbody>
      </table>
      <table v-else class="hover">
        <tbody>
        <tr>
          <td class="td-width"><p>Email</p></td>
          <td><span>{{contact.email}}</span></td>
        </tr>
        <tr>
          <td class="td-width"><p>Tel (Mobil)</p></td>
          <td><span>{{contact.mobilePhone}}</span></td>
        </tr>
        <tr>
          <td class="td-width"><p>Tel (Zuhause)</p></td>
          <td><span>{{contact.phone}}</span></td>
        </tr>
        <tr>
          <td class="td-width"><p>Tel (Arbeit)</p></td>
          <td><span>{{contact.businessPhone}}</span></td>
        </tr>
        <tr>
          <td class="td-width"><p>Fax</p></td>
          <td><span>{{contact.fax}}</span></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ContactView',

    props: {
      contact: {
        required: true
      },
      insurance: {
        required: false
      },
      raw: {
        required: false
      },
    }
  }
</script>

<style lang="scss" scoped>
  p {
    margin: 0;
  }

  span {
    color: cornflowerblue;
  }

  table {
    border-collapse: unset;
  }

  tbody th, tbody td {
    padding: 0;
  }

  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: transparent;
  }

  .td-width {
    width: 210px;
  }
</style>