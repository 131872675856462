<template>
  <div v-if="person">
    <div v-if="raw">
      <div v-if="!fullNameDisabled">
        <span style="color: inherit;" v-if="!salutationDisabled">{{labels.salutation[person.salutation]}}</span>
        <span style="color: inherit;" v-if="!titleDisabled">&nbsp;{{person.title}}</span>&nbsp;{{person.firstname}} {{person.lastname}}
      </div>
      <div v-if="!noAddress && person.address">{{person.address.street}} {{person.address.streetNumber}}</div>
      <div v-if="!noAddress && person.address">{{person.address.country}} {{person.address.zipcode}} {{person.address.placeOfResidence}}</div>
      <div v-if="!birthDateDisabled">geboren am : {{person.birthdate | moment("DD.MM.YYYY")}}</div>
      <p v-if="showMore">Beruf : {{person.profession}}</p>
      <p v-if="showMore">darf Kind abholen : {{booleanText(person.pickUpEntitled)}}</p>
      <p v-if="showMore">Sorgerecht : {{booleanText(person.custody)}}</p>
      <p v-if="showMore">alleiniges Sorgerecht : {{booleanText(person.soleCustody)}}</p>
      <p v-if="showMore">alleinerziehend : {{booleanText(person.singleParent)}}</p>
      <p>Einwilligung Datenverarbeitung : {{booleanText(person.dsgvoConfirmationObtained)}}</p>
      <p v-if="showMore && showPortalLogin && isBeta">Zugang Elternportal : {{booleanText(person.portalLoginAllowed)}}</p>
    </div>
    <div v-else>
      <p v-if="!fullNameDisabled"><span v-if="!salutationDisabled" class="formatted">{{labels.salutation[person.salutation]}}</span>
        <span v-if="!titleDisabled" class="formatted"> {{person.title}}&nbsp;</span>
        <span class="formatted"> {{person.firstname}} {{person.lastname}}</span>&nbsp;
        <span v-if="sexEnabled"> (<b :class="person.sex">{{labels.sex[person.sex]}}</b>)</span>&nbsp;
      </p>
      <p v-if="!noAddress && person.address"><span class="formatted">{{person.address.street}} {{person.address.streetNumber}}</span></p>
      <p v-if="!noAddress && person.address"><span class="formatted">{{person.address.zipcode}} {{person.address.placeOfResidence}}</span></p>
      <p v-if="!noAddress && person.address"><span class="formatted">{{labels.country[person.address.country]}}</span></p>
      <table class="hover">
        <tbody>
          <tr v-if="!birthDateDisabled">
            <td class="td-width">geboren am</td>
            <td><p><span>{{person.birthdate | moment("DD.MM.YYYY")}}</span></p></td>
          </tr>
          <tr v-if="showMore">
            <td class="td-width">Beruf</td>
            <td><p><span>{{person.profession}}</span></p></td>
          </tr>
          <tr v-if="showMore">
            <td class="td-width">darf Kind abholen</td>
            <td><p><span :class="booleanText(person.pickUpEntitled)">{{booleanText(person.pickUpEntitled)}}</span></p></td>
          </tr>
          <tr v-if="showMore">
            <td class="td-width">Sorgerecht</td>
            <td><p><span>{{booleanText(person.custody)}}</span></p></td>
          </tr>
          <tr v-if="showMore">
            <td class="td-width">alleiniges Sorgerecht</td>
            <td><p><span class="formatted">{{booleanText(person.soleCustody)}}</span></p></td>
          </tr>
          <tr v-if="showMore">
            <td class="td-width">alleinerziehend</td>
            <td><p><span class="formatted">{{booleanText(person.singleParent)}}</span></p></td>
          </tr>
          <tr v-if="!gdprDisabled">
            <td class="td-width">Einwilligung Datenverarbeitung</td>
            <td><p><span :class="booleanText(person.dsgvoConfirmationObtained)">{{booleanText(person.dsgvoConfirmationObtained)}}</span></p></td>
            <md-tooltip>
              <div v-if="!person.dsgvoConfirmationObtained">
                Die Einwilligung gemäß Datenschutz-Grundverordnung (Art. 6 DSGVO)<br>
                zur Verarbeitung dieser personenbezogenen Daten muss<br>
                seitens <b>{{person.fullName}}</b> schriftlich gegeben sein.
              </div>
            </md-tooltip>
          </tr>
          <tr v-if="showMore && showPortalLogin && isBeta">
            <td class="td-width"><router-link :to="'/Eltern/'+ person.id">Zugang Elternportal</router-link>
              <md-tooltip>Zugang Elternportal für <b>{{person.fullName}}</b> bearbeiten</md-tooltip>
            </td>
            <td>
              <span>{{booleanText(person.portalLoginAllowed)}}</span>&nbsp;
              <md-icon v-if="person.portalLoginAllowed" style="color: green">desktop_windows</md-icon>
              <md-icon v-else style="color: lightgrey">desktop_access_disabled</md-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="facilityRef && !gdprDisabled && !isSage">
      <br>
      <div @click="confirmDownloadPersonalInformation = true" class="button success" :disabled="sending">
        <i class="fi-page-multiple"></i>&nbsp;&nbsp;&nbsp;Datenauskunft PDF
        <md-tooltip>
          Vollständige Datenauskunft gemäß Datenschutz-Grundverordnung (Art. 15 DSGVO)<br>
          in Bezug auf die Verarbeitung dieser Daten
          für <b>{{person.firstname}} {{person.lastname}}</b> als PDF herunterladen.
        </md-tooltip>
      </div>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmDownloadPersonalInformation">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><i class="fi-page-multiple"></i>&nbsp;&nbsp;&nbsp;Vollständige Datenauskunft gemäß Art. 15 DSGVO
        </md-dialog-title>
        <div class="cell text-center">
          <md-content>Soll gemäß Datenschutz-Grundverordnung (Art. 15 DSGVO) eine vollständige<br>
            Datenauskunft für <span class="title">{{person.firstname}} {{person.lastname}}</span> erzeugt und als PDF heruntergeladen werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button :disabled="sending" @click="onDownloadPersonalInformation" class="button success"><i class="fi-download"></i>&nbsp;&nbsp;&nbsp;Ja,
            Datenauskunft erzeugen
          </button>
          <button :disabled="sending" @click="confirmDownloadPersonalInformation = false"
                  class="button alert"
                  style="margin-left: 1rem;"
                  type="button">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button @click="confirmDownloadPersonalInformation = false" class="close-button" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>
  </div>
</template>

<script>
  import PdfService from "../../services/PdfService";
  import HttpErrorHandler from "../../services/HttpErrorHandler";

  export default {
    name: 'PersonView',

    beforeDestroy() {
      this.cleanPDF();
    },

    props: {
      person: {
        required: true
      },
      raw: {
        required: false
      },
      sexEnabled: {
        required: false
      },
      fullNameDisabled: {
        required: false,
      },
      salutationDisabled: {
        required: false
      },
      titleDisabled: {
        required: false
      },
      birthDateDisabled: {
        required: false
      },
      gdprDisabled: {
        required: false
      },
      noAddress: {
        required: false
      },
      showMore: {
        required: false
      },
      showPortalLogin: {
        required: false
      },
      facilityRef: {
        required: false
      },
    },

    data() {
      return {
        sending: false,
        confirmDownloadPersonalInformation: false,

        personalInformationPdf: {
          url: null,
          link: null,
          blob: null,
        },
      }
    },

    methods: {
      booleanText(value) {
        return value ? 'ja' : 'nein';
      },

      cleanPDF() {
        if (this.personalInformationPdf.url) {
          this.personalInformationPdf.url = window.URL.revokeObjectURL(this.personalInformationPdf.url);
          this.personalInformationPdf.blob = null;
        }
        this.personalInformationPdf.url = null;
      },

      onDownloadPersonalInformation() {
        if (this.facilityRef) {
          let config = [{
            facilityReference: this.facilityRef,
            childId: '',
            dsgvoPersonId: this.person.id,
            template: {
              type: 'DSGVO',
              content: '',
              name: '',
              standard: false,
              printMargins: {
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
              },
              forceUseOfLetterPaper: 0,
            },
            year: 0,
            month: 0,
            preview: false,
          }];

          this.cleanPDF();
          this.getTemplateData(this.facilityRef, config, this.personalInformationPdf)
        }
      },

      getTemplateData(referenceNumber, config, pdf) {
        this.sending = true;
        PdfService.getTemplateData(config)
          .then(response => {
            this.sending = false;
            this.createPdfForTemplate(referenceNumber, response.data, pdf)
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der PDF Template Daten für Person <b>' + this.person.firstname + ' ' + this.person.lastname + '</b> zur Erstellung der Datenauskunft');
          })
      },

      createPdfForTemplate(referenceNumber, templateData, pdf) {
        this.sending = true;
        PdfService.createPdf(templateData)
          .then(response => {
            setTimeout(() => {
              pdf.blob = new Blob([response.data, 'utf-8'], {type: "application/pdf"});
              pdf.url = window.URL.createObjectURL(pdf.blob);

              if (pdf.link) {
                document.body.removeChild(pdf.link);
              }
              pdf.link = document.createElement('a');
              const id = this.organization.name + ' (' + referenceNumber + ') Datenauskunft (Artikel 15 DSGVO) ' + this.person.firstname + ' ' + this.person.lastname;
              pdf.link.href = pdf.url;
              pdf.link.setAttribute('download', id + '.pdf');
              pdf.link.setAttribute('id', id);
              document.body.appendChild(pdf.link);
              pdf.link.click();

              this.sending = false;
              this.confirmDownloadPersonalInformation = false;
            }, 50);
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen des PDF für die Datenauskunft für Person <b>' + this.person.firstname + ' ' + this.person.lastname + '</b>');
          })
      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },

      organization() {
        return this.$store.getters.organization;
      },

      isBeta() {
        return this.$store.getters.beta
      },

      isSage() {
        return this.$store.getters.sage
      },
    }
  }
</script>

<style lang="scss" scoped>
  .md-dialog /deep/.md-dialog-container {
    max-width: 700px;
  }

  p {
    margin: 0;
  }

  span {
    color: cornflowerblue;
  }

  table {
    border-collapse: unset;
  }

  tbody th, tbody td {
    padding: 0;
  }

  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: transparent;
  }

  .td-width {
    width: 210px;
  }

  .ja {
    color: forestgreen;
    font-weight: bold;
    font-size: medium;
  }

  .nein {
    color: red;
    font-weight: bold;
    font-size: medium;
  }

  .MALE {
    font-size: inherit;
  }

  .FEMALE {
    font-size: inherit;
  }

  .DIVERSE {
    font-size: inherit;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

  .title {
    color: chocolate;
    font-weight: bold;
    font-size: larger;
  }
</style>