import HttpInterceptor from "./HttpInterceptor";

export default {

  name: 'GroupService',

  getAllGroups() {
    return HttpInterceptor.httpClient().get('groups/all');
  },

  getAllOrganizationGroups() {
    return HttpInterceptor.httpClient().get('groups/organization/visible');
  },

  getAllFacilityGroups(facilityReferenceNo) {
    return HttpInterceptor.httpClient().get('groups/facility/' + facilityReferenceNo);
  },

  getChildrenOfGroup(groupId) {
    return HttpInterceptor.httpClient().get('groups/' + groupId + '/children');
  },

  deleteGroup(groupId) {
    return HttpInterceptor.httpClient().delete('groups/' + groupId);
  },

  updateGroup(group) {
    return HttpInterceptor.httpClient().put('groups/update', group);
  },

  assignChildToGroup(childId, groupId) {
    return HttpInterceptor.httpClient().put('groups/' + groupId + '/child/' + childId + '/assign');
  },

  removeChildFromGroup(childId, groupId) {
    return HttpInterceptor.httpClient().put('groups/' + groupId + '/child/' + childId + '/remove');
  },
}
