<template>
  <div v-if="child">
    <md-card md-with-hover class="text-left">
      <md-card-header>
        <div class="md-layout">
          <div class="md-layout-item md-size-20">
            <ImageView :imageId="child.imageId" size="tiny"></ImageView>
          </div>
          <div class="md-layout-item">
            <ChildBriefRecord ref="ChildBriefRecordRef" :child="child" :groups="groups" :htmlTitle="true"/>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item">
            <ChildBriefRecord ref="ChildBriefRecordRef" :child="child" :groups="groups" :htmlBody="true"/>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
  import ImageView from "@/components/views/ImageView";
  import ChildBriefRecord from "@/components/fabs/ChildBriefRecord";

  export default {
    name: 'ChildCard',

    components: {
      ImageView,
      ChildBriefRecord,
    },

    props: {
      child: {
        required: false
      },
      groups: {
        required: false
      },
    }
  }
</script>

<style lang="scss" scoped>
  span {
    color: cornflowerblue;
    font-weight: bold;
  }

  .md-title {
    color: coral;
    padding-bottom: 1rem;
  }

  .md-card.md-with-hover {
    cursor: default;
  }
</style>
