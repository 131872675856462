<template>
  <div class="cell text-center">

    <md-card v-if="module" md-with-hover class="text-left">
      <md-card-header>
        <div class="md-layout">
          <div class="md-layout-item md-size-15">
            <md-icon class="md-size-2x" style="color: orange">no_sim</md-icon>
          </div>
          <div class="md-layout-item">
            <div class="md-title">Modul nicht gebucht</div>
            <div v-if="alternativeText" class="md-subhead" style="font-size: larger;" v-html="alternativeText"></div>
            <div v-else class="md-subhead" style="font-size: larger;">Die Erstellung und Verwaltung von <span>{{moduleFunction}}</span> kann mit dem Modul <span>{{module.label}}</span> durchgeführt werden.</div>
            <hr style="margin-bottom: 0;">
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-15">
          </div>
          <div class="md-layout-item">
            <div class="md-title" style="font-size: large;">Beschreibung <span>{{module.label}}</span></div>
            <div class="md-subhead" style="font-size: larger;">{{module.description}}</div>
            <br><br>
            <router-link to="/Verwaltung/Traeger/Vertrag">
              <button class="button success expanded">
                <p class="md-title" style="font-size: larger;"><b>{{module.label}}</b> jetzt bestellen</p>
                <md-tooltip><b>Sie brauchen mehr?</b> Hier geht es zur Bestellung!</md-tooltip>
              </button>
            </router-link>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
  export default {
    name: 'NoModuleCard',
    props: {
      moduleType: {
        required: false
      },
      moduleFunction: {
        required: false
      },
      alternativeText: {
        required: false
      },
    },

    created() {
      this.setModule();
    },

    data: () => ({
      module: null,
    }),

    methods: {
      setModule() {
        this.modules.forEach(module => {
          if (module.type === this.moduleType) {
            this.module = module;
          }
        })
      },
    },

    computed: {
      modules() {
        return this.$store.getters.modules
      },
    },
  }
</script>

<style lang="scss" scoped>
  span {
    color: cornflowerblue;
    font-weight: bold;
  }

  .md-card {
    max-width: 600px;
    min-width: 200px;
    margin: 4px;
    display: inline-block;
    vertical-align: top;
  }

  a:hover {
    text-decoration: none;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }
</style>
