import HttpInterceptor from "./HttpInterceptor";

export default {

  name: 'ParentsService',

  getInfo() {
    return HttpInterceptor.httpClient().get('parents/info');
  },

  getRelatesPersons(facilityReferenceNo) {
    return HttpInterceptor.httpClient().get('parents/' + facilityReferenceNo + '/relatedpersons');
  },

  updateLoginForPerson(person) {
    return HttpInterceptor.httpClient().put('parents/' + person.id + '/login/update', person);
  },
}
